import Router from 'next/router'
import React, { Component } from 'react'

export default class Index extends Component {
  componentDidMount = (): void => {
    Router.push('/welcome').catch(console.error)
  }

  render(): JSX.Element {
    return <div />
  }
}
